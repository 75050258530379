<template>
  <div>
    <h1>About</h1>
    <p>Let's talk about!</p>
  </div>
</template>

<script>
export default {}
</script>
